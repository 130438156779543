import { Item, logEvent as logFirebaseEvent } from '@firebase/analytics';
import { analyticsPromise, functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';

import { ProductDocument } from '@util/types/firestore/products';
import { MasterDocument } from '@util/firestore/master';
import { BlogDocument } from '@util/types/firestore/blog';

import { EventTypes, PixelEvents } from './analytics.types';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const isProd = process.env.NODE_ENV === 'production';
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID

const fbq:
  | undefined
  | ((
      type: 'track',
      event: PixelEvents,
      params?: any,
      eventID?: { eventID: string }
    ) => void) =
  typeof window === 'undefined' ? undefined : (window as any).fbq;

const gtag:
  | undefined
  | ((event: string, action: string, value: any) => void) =
  typeof window === 'undefined' ? undefined : (window as any).gtag;

export const pageView = (url: string) => {
  if (fbq) fbq('track', 'PageView');
  if (gtag && GA_TRACKING_ID) {
    gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// all GA4 events not listed below
export async function logEvent(
  eventName: EventTypes,
  eventParams?: {
    coupon?: string | undefined;
    currency?: string | undefined;
    items?: Item[] | undefined;
    payment_type?: string | undefined;
    value?: number | undefined;
    [key: string]: any;
  },
  uid?: string
) {
  if (!isProd) return;
  try {
    // fb pixel events
    if (eventName === 'add_payment_info') addPaymentInfo(eventParams as any);
    if (eventName === 'add_to_wishlist') addToWishlist(eventParams!, uid!);
    if (eventName === 'purchase') logPurchase(eventParams!, uid!);
    if (eventName === 'created_offer') logOffer(eventParams!.items![0], uid!);
    // all other events - DON'T COMMENT OUT THIS CODE
    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, eventName as string, eventParams);
  } catch (e) {
    logError(e);
  }
}

// Main analytics functions with extra config

export async function viewListing(
  item: ProductDocument,
  referrer: string,
  uid?: string
) {
  if (!isProd) return null;

  try {
    // Push to Firebase Cloud Function
    await httpsCallable(
      functions,
      'viewedProduct'
    )({
      item,
      total: item.price,
      from_web: true,
    });

    // Push to the DataLayer
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });

      window.dataLayer.push({
        event: "rr_view_item",
        rr_cart_id: uid || null,
        ecommerce: {
          currencyCode: "USD",
          detail: {
            products: [
              {
                source: item.external_source || 'gearfocus',
                name: item.title,
                id: item.id,
                image: item.thumbnail,
                price: item.price,
                brand: item.brand,
                categories: item.categories,
                master: item.master || null,
                slug: item.slug,
                url: `https://www.gearfocus.com/products/${item.slug}`,
              },
            ],
          },
        },
      });

      console.log("DataLayer", window.dataLayer)
    }

    // Push to Facebook Pixel
    if (fbq) {
      fbq(
        'track',
        'ViewContent',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_category: item.categories[0],
          content_type: referrer,
          contents: [{ id: item.id, quantity: 1 }],
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.id }
      );
    }

    // Push to Firebase Analytics
    const analytics = await analyticsPromise;
    if (analytics) {
      logFirebaseEvent(analytics, 'view_item', {
        currency: 'usd',
        value: item.price,
        items: [getItem(item)],
      });
    }
  } catch (e) {
    logError(e);
  }
}

export async function viewMaster(
  item: MasterDocument,
  uid?: string
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'master-viewMasterProduct'
    )({
      item,
      from_web: true,
    });

    // Push to the DataLayer
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });

      window.dataLayer.push({
        event: "rr_view_item",
        rr_cart_id: uid || null,
        ecommerce: {
          currencyCode: "USD",
          detail: {
            products: [
              {
                source: 'gearfocus',
                master: true,
                name: item.title,
                id: item.id,
                image: item.images[0],
                price: item.price,
                brand: item.brand,
                series: item.series,
                model: item.model,
                slug: item.slug,
                url: `https://www.gearfocus.com/m/${item.slug}`,
              },
            ],
          },
        },
      });
    }
  } catch (e) {
    logError(e);
  }
}

export async function viewBlogPost(
  item: BlogDocument
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'blog-viewedBlog'
    )({
      item,
      from_web: true,
    });
  } catch (e) {
    logError(e);
  }
}

export async function clickAffiliate(
  collection: string,
  document: string,
  source: string
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'affiliate-clickAffiliateLink'
    )({
      collection,
      document,
      source,
      from_web: true,
    });
  } catch (e) {
    logError(e);
  }
}

export async function logInitCheckout(
  items: ProductDocument[],
  total: number,
  uid: string
) {
  if (!isProd) return;
  try {
    if (fbq) {
      fbq(
        'track',
        'InitiateCheckout',
        {
          content_ids: items.map((i) => i.id),
          contents: items.map((i) => {
            return { id: i.id, quantity: 1 };
          }),
          num_items: items.length,
          value: total,
          currency: 'USD',
        },
        { eventID: uid + ' ' + items[items.length - 1].id }
      );
    }

    await httpsCallable(
      functions,
      'initiateCheckout'
    )({
      items,
      total,
      isComplete: false,
      from_web: true,
    });

    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, 'begin_checkout', {
        currency: 'usd',
        value: total,
        items: getItems(items),
      });
  } catch (e) {
    logError(e);
  }
}

export async function logAddToCart(
  item: ProductDocument,
  total: number,
  uid: string
) {
  if (!isProd) return;
  try {
    if (fbq)
      fbq(
        'track',
        'AddToCart',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_type: item.categories[0],
          contents: [{ id: item.id, quantity: 1 }],
          value: total,
          currency: 'USD',
        },
        { eventID: uid + '_' + item.id }
      );
    await httpsCallable<
      { item: ProductDocument; total: number; from_web: boolean },
      void
    >(
      functions,
      'addToCart'
    )({ item, total, from_web: true });
    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, 'add_to_cart', {
        currency: 'usd',
        value: item.price,
        items: [getItem(item)],
      });
  } catch (e) {
    logError(e);
  }
}

export async function completeRegistration(
  uid: string,
  method: string,
  email: string
) {
  try {
    await httpsCallable<{ email: string; from_web: boolean }, void>(
      functions,
      'completeRegistration'
    )({ email, from_web: true });
    if (fbq)
      fbq(
        'track',
        'CompleteRegistration',
        {
          status: true,
          content_name: method,
        },
        { eventID: uid }
      );
    const analytics = await analyticsPromise;
    if (analytics) logFirebaseEvent(analytics, 'sign_up', { method });
  } catch (e) {
    logError(e);
  }
}

// helper fb pixel functions

function logPurchase(
  eventParams: { transaction_id?: string; value?: number },
  uid: string
) {
  if (!isProd) return;
  try {
    if (fbq)
      fbq(
        'track',
        'Purchase',
        { value: eventParams.value, currency: 'USD' },
        { eventID: uid + ' ' + eventParams.transaction_id! }
      );
  } catch (e) {
    logError(e);
  }
}

function addPaymentInfo(eventParams: { id: string; is_payout: boolean }) {
  try {
    if (fbq)
      fbq(
        'track',
        'AddPaymentInfo',
        {
          content_ids: [eventParams.id],
          content_category: eventParams.is_payout
            ? 'For Payout'
            : 'For Purchase',
        },
        { eventID: eventParams.id }
      );
  } catch (e) {
    logError(e);
  }
}

function addToWishlist(
  eventParams: {
    currency?: string;
    items?: Item[];
    value?: number;
  },
  uid: string
) {
  try {
    if (fbq)
      fbq(
        'track',
        'AddToWishlist',
        {
          content_ids: [eventParams.items![0].item_id!],
          content_name: eventParams.items![0].item_name,
          value: eventParams.value,
          currency: eventParams.currency!,
        },
        { eventID: uid + ' ' + eventParams.items![0].item_id! }
      );
  } catch (e) {
    logError(e);
  }
}

function logOffer(item: Item, uid: string) {
  try {
    if (fbq)
      fbq(
        'track',
        'Lead',
        {
          content_category: item.item_category,
          content_name: item.item_name,
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.id }
      );
  } catch (e) {
    logError(e);
  }
}

export const getItems = (items: ProductDocument[]) => {
  return items.map((i) => getItem(i));
};

const getItem = (i: ProductDocument) => {
  return {
    item_id: i.id,
    item_name: i.title,
    item_brand: i.brand || 'Google',
    item_category: i.categories[0],
    price: i.price,
    quantity: 1,
  };
};
